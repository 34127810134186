document.addEventListener("DOMContentLoaded", function() {

  let root = document.documentElement,
      timeout = false, // holder for timeout id
      delay = 250, // delay after event is "complete" to run callback
      calls = 0;

  // Create CSS variable for window.innerWidth() 
  function createWindowInnerWidthCssVar() {
    let root = document.documentElement;
    let actualInnerWidth = document.body.clientWidth;
    root.style.setProperty('--window-inner-width', actualInnerWidth + "px");
  }

  // window.resize event listener
  window.addEventListener('resize', function() {
    // clear the timeout
    clearTimeout(timeout);
    // start timing for event "completion"
    timeout = setTimeout(createWindowInnerWidthCssVar, delay);
  });

  createWindowInnerWidthCssVar();

});